import React from "react";
import ProgressAnalysisEyeReport from "../../../ProgressionAnalysisReport/ProgressAnaysisEyeReport";
import { Typography } from "@mui/material";

export default function FetchExam({ exam, isSelected, selectedExam, t }) {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {isSelected && selectedExam ? (
        <>
          <Typography variant="body1">
            {t("progress_analysis_exam_date")}:{" "}
            {new Date(exam.creationDate)
              .toLocaleDateString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
              .replace(/\//g, "-")}
          </Typography>
        </>
      ) : null}

      {isSelected && selectedExam ? (
        <div style={{ overflowY: "auto", flexGrow: 1, overflow: "hidden" }}>
          {isSelected && selectedExam.reportData && (
            <ProgressAnalysisEyeReport
              key={selectedExam.id}
              examReport={selectedExam.reportData}
              examData={selectedExam}
              scale={0.9}
            />
          )}
        </div>
      ) : (
        <p></p>
      )}
    </div>
  );
}
