import { useCallback, useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import html2canvas from "html2canvas";
import {
  getChartSpots,
  calculateNeighbours,
  getPatternDevChartSpots,
  getTotalChartSpots,
  getGazeChartData,
} from "../../utils/examReportsHelper";
import { ReportEachSectionContext } from "../../contexts/CommentContext";
import { ErrorMessageComponent } from "../../components/UI/ErrorMessage";
import TotalGraph from "../Report/VisualFieldReport/EyeReport/TotalGraph";
import NewPatternGraph from "../Report/VisualFieldReport/EyeReport/NewPatternGraph";
import NumericalGraph from "../Report/VisualFieldReport/EyeReport/NumericalGraph";
import { useMediaQuery } from "react-responsive";

const ProgressAnalysisEyeReport = ({ examReport, examData, scale }) => {
  const { eyeReport } = useContext(ReportEachSectionContext);
  const [chartData, setChartData] = useState({
    number: undefined,
    pattern: undefined,
    totalDev: undefined,
    patternDev: undefined,
    gaze: undefined,
  });
  const [imageSrc, setImageSrc] = useState(null);

  const isNotRlFast = examReport?.exam?.algorithm !== "SCREEN_FAST";
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1200 });

  const handleShowImage = useCallback(async () => {
    const element = document.getElementById(`eye-report-${eyeReport}`);
    if (!element) {
      console.error("Element not found!");
      return;
    }

    try {
      const canvas = await html2canvas(element, { scale: 2 });
      setImageSrc(canvas.toDataURL("image/jpg"));
    } catch (error) {
      console.error("Error generating image:", error);
    }
  }, [eyeReport]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleShowImage();
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [handleShowImage]);

  useEffect(() => {
    if (!examReport) return;

    const spots = getChartSpots(examReport);
    const finalPatternSpots = calculateNeighbours(spots);
    const { gazeSpots } = getGazeChartData(examReport);

    setChartData({
      number: spots,
      pattern: finalPatternSpots,
      totalDev: getTotalChartSpots(examReport),
      patternDev: getPatternDevChartSpots(examReport),
      gaze: gazeSpots,
    });
  }, [examReport]);

  if (!eyeReport) return null;
  return (
    <div
      className="retinalogik-section"
      style={{ maxWidth: "100%", overflowX: "auto" }}
    >
      {examReport?.postProcessing ? (
        imageSrc ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              className="retinalogik-report"
              src={imageSrc}
              alt="Generated"
              width={"90%"}
              height={"auto"}
            />
          </div>
        ) : (
          <div id={`eye-report-${eyeReport}`} style={{ width: "100%" }}>
            <Grid
              container
              spacing={3}
              sx={{ width: "100%", whiteSpace: "nowrap" }}
            >
              {isNotRlFast && (
                <>
                  <Grid container spacing={2} justifyContent="space-between">
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper
                        elevation={1}
                        sx={{
                          paddingLeft: isTabletOrMobile ? 4 : 6,
                          textAlign: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "0.9rem",
                            textAlign: "center",
                          }}
                        >
                          Grey Scale Plot
                        </Typography>
                        <div
                          style={{
                            transform: "scale(0.8)",
                            transformOrigin: "top left",
                            width: "100%",
                            height: "auto",
                            marginTop: 10,
                          }}
                        >
                          <NewPatternGraph
                            pressedPoints={chartData.pattern || []}
                            eye={eyeReport}
                            gridType={examReport?.exam?.grid}
                          />
                        </div>
                      </Paper>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      ml={isTabletOrMobile ? 2 : 7}
                    >
                      <Paper
                        elevation={1}
                        sx={{
                          padding: 2,
                          textAlign: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "0.9rem",
                            textAlign: "center",
                          }}
                        >
                          Numerical Plot
                        </Typography>
                        <div
                          style={{
                            transform: "scale(0.8)",
                            transformOrigin: "top left",
                            width: "100%",
                            height: "auto",
                          }}
                        >
                          <NumericalGraph
                            processedPoints={
                              examReport?.postProcessing?.processedPoints || []
                            }
                            gridType={examReport?.exam?.grid}
                            useDefaultPadding={true}
                          />
                        </div>
                      </Paper>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      ml={isTabletOrMobile ? 5 : 0}
                    >
                      <Paper
                        elevation={1}
                        sx={{
                          padding: 1.5,
                          textAlign: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "0.9rem",
                          }}
                        >
                          Pattern Plot
                        </Typography>
                        <div
                          style={{
                            transform: "scale(0.8)",
                            transformOrigin: "top left",
                            width: "100%",
                            height: "auto",
                            marginTop: 20,
                          }}
                        >
                          <TotalGraph
                            pressedPoints={chartData.totalDev || []}
                            eye={eyeReport}
                            gridType={examReport?.exam?.grid}
                          />
                        </div>
                      </Paper>
                    </Grid>
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Typography sx={{ fontWeight: "bold", marginTop: -10 }}>
                  MD:{" "}
                  {examData?.reportData?.postProcessing?.totalMeanDeviation !==
                  null
                    ? `${Number(
                        examData?.reportData?.postProcessing?.totalMeanDeviation
                      ).toFixed(2)} dB`
                    : "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Typography sx={{ fontWeight: "bold", marginTop: -10 }}>
                  PSD:{" "}
                  {examData.reportData.postProcessing
                    ?.patternStandardDeviation !== null
                    ? `${Number(
                        examData.reportData.postProcessing
                          ?.patternStandardDeviation
                      ).toFixed(2)} dB`
                    : "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Typography sx={{ fontWeight: "bold", marginTop: -10 }}>
                  FP:{" "}
                  {examData.reportData.stats?.falsePositivePercentage
                    ? `${Number(examData.reportData.stats?.falsePositivePercentage).toFixed(2)} %`
                    : "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Typography sx={{ fontWeight: "bold", marginTop: -10 }}>
                  FL:{" "}
                  {examData.reportData.stats?.fixationLossPercentage !== null
                    ? `${Number(examData.reportData.stats?.fixationLossPercentage).toFixed(2)} %`
                    : "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Typography sx={{ fontWeight: "bold", marginTop: -10 }}>
                  RLFI:{" "}
                  {examData?.reportData?.postProcessing
                    ?.retinaLogikFieldIndex !== null
                    ? `${Number(examData?.reportData?.postProcessing?.retinaLogikFieldIndex).toFixed(2)} %`
                    : "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Typography sx={{ fontWeight: "bold", marginTop: -10 }}>
                  FN:{" "}
                  {examData.reportData.stats?.falseNegativeCount !== null
                    ? `${examData.reportData.stats?.falseNegativeCount} %`
                    : "N/A"}
                </Typography>
              </Grid>
            </Grid>
          </div>
        )
      ) : (
        <ErrorMessageComponent errorBit={1} />
      )}
    </div>
  );
};

export default ProgressAnalysisEyeReport;
