import { useLocation, useParams } from "react-router-dom";
import PageIntro from "../../components/UI/PageIntro";
import { useCallback, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getPatientById } from "../../services/Patient";
import useClinic from "../../hooks/useClinic";
import { getClinics } from "../../services/Clinic";
import DownloadButtons from "../../components/UI/DownloadButtons";
import { ValidatePDFContext } from "../../contexts/ValidatePDFContext";
import ProgressionAnalysisReportContainer from "../../containers/ProgressionAnalysisReport";
import { CommentModalContextProvider } from "../../contexts/CommentContext";
import Breadcrumb from "../../components/UI/ReportBreadcrumb";
import { Box } from "@mui/material";
import { PageContainerWrapper } from "../../styles/muiStylesHelper";
import { getSubmisionData } from "../../services/Exam";

const ProgressionAnalysisReport = () => {
  const location = useLocation();
  const { patientId } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const { clinicId, clinicLicensing, setClinicLicensing } = useClinic();
  const [patient, setPatient] = useState(null);
  let isLoading = false;
  const [report, setReport] = useState(null);
  const forBackendPdf = false;
  const { exam } = location?.state || {};
  const fetchAll = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const [patient, clinicData] = await Promise.all([
        getPatientById(token, clinicId, patientId),
        getClinics(token, clinicId),
      ]);

      if (clinicData?.licensing) setClinicLicensing(clinicData.licensing);
      setPatient(patient);

      if (exam?.length > 0) {
        const enrichedExams = await Promise.all(
          exam.map(async (exam) => {
            try {
              const visualFieldSectionId = exam.visualFieldSections?.[0]?.id;
              const reportData = await getSubmisionData(
                token,
                clinicId,
                exam.patient.id,
                exam.id,
                visualFieldSectionId
              );

              if (reportData) {
                const parsedReportData = JSON.parse(reportData);

                if (
                  parsedReportData?.postProcessing?.retinaLogikFieldIndex !==
                  null
                ) {
                  parsedReportData.postProcessing.retinaLogikFieldIndex *= 100;
                }
                if (parsedReportData?.stats?.fixationLossPercentage != null) {
                  parsedReportData.stats.fixationLossPercentage *= 100;
                }
                if (parsedReportData?.stats?.falsePositivePercentage != null) {
                  parsedReportData.stats.falsePositivePercentage *= 100;
                }

                return {
                  ...exam,
                  reportData: parsedReportData,
                  visualFieldSectionId,
                };
              }
            } catch (err) {
              console.error(
                `Error fetching report data for exam ${exam.id}:`,
                err
              );
            }
            return exam;
          })
        );
        setReport(enrichedExams);
      }
    } catch (e) {
      console.error("Error fetching data:", e);
    }
  }, [clinicId, exam, getAccessTokenSilently, patientId, setClinicLicensing]);
  useEffect(() => {
    fetchAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ValidatePDFContext.Provider value={{ forBackendPdf }}>
      <CommentModalContextProvider>
        <Box
          sx={() => PageContainerWrapper()}
          px={{ xs: 2, sm: 4, md: 8, lg: 12 }}
          mb={{ xs: 2 }}
        >
          <PageIntro
            pageTitle="Report"
            showBreadcrumb
            BreadcrumbComponent={
              <Breadcrumb
                isLoading={isLoading}
                patientId={patientId}
                patientName={`${patient?.firstName} ${patient?.lastName}`}
              />
            }
            showAddButton={false}
            showDeleteButton={false}
            showFilterInput={false}
            desktopMarginTop={3}
            desktopMarginBottom={3}
          />
          <DownloadButtons
            isLoading={isLoading}
            fileInfo={{}}
            reportInfoError={false}
            downloadPdf={null}
            shouldDisplayDicomButton={
              clinicLicensing?.canUseDicomReports === true
            }
            shouldDisplayPngButton={clinicLicensing?.canUsePngReports === true}
          />
          {report && (
            <ProgressionAnalysisReportContainer
              report={report}
              patient={patient}
              clinicId={clinicId}
            />
          )}
        </Box>
      </CommentModalContextProvider>
    </ValidatePDFContext.Provider>
  );
};

export default ProgressionAnalysisReport;
