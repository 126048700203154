import React, { useState, useContext } from "react";
import { ValidatePDFContext } from "../../../contexts/ValidatePDFContext";
import {
  Grid,
  Typography,
  TextField,
  DialogActions,
  Button,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { AddCommentButton } from "../ReportInfoShared";
import { useTranslation } from "react-i18next";
import {
  ModalCustomAdd,
  ModalCustomCancel,
} from "../../../styles/muiStylesHelper";
import { addProgressAnalysisCommentReport } from "../../../services/Exam";
import { useAuth0 } from "@auth0/auth0-react";

const ReportInfoHeaderComponentAnalysis = ({
  isWiderThanSmallScreen,
  shouldReduceFontSizeInPdf,
  dynamicFontSize,
  name,
  rightText,
  clinicId,
  patient,
  progressionAnalysisId,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { forBackendPdf } = useContext(ValidatePDFContext);
  const { t } = useTranslation();
  const [toggleState, setToggleState] = useState({
    Glaucoma: false,
    AMD: false,
    DR: false,
  });
  const [open, setOpen] = useState(false);
  const [commentValue, setCommentValue] = useState("");
  const maxLength = 200;

  const handleToggleChange = (condition) => {
    setToggleState((prev) => ({
      ...prev,
      [condition]: !prev[condition],
    }));
  };

  const handleChange = (e) => setCommentValue(e.target.value);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async () => {
    const selectedConditions = Object.keys(toggleState)
      .filter((condition) => toggleState[condition])
      .map((condition) => condition);

    const formattedConditions = JSON.stringify(selectedConditions);

    const commentData = {
      content: commentValue,
      conditions: formattedConditions,
      otherConditions: "None.",
    };

    try {
      const token = await getAccessTokenSilently();
      const response = await addProgressAnalysisCommentReport(
        token,
        clinicId,
        patient?.id,
        progressionAnalysisId,
        commentData
      );
      if (response) {
        alert(t("comment_added_successfully"));
        setCommentValue("");
        setToggleState({ Glaucoma: false, AMD: false, DR: false });
        handleClose();
      }
    } catch (error) {
      console.error(error);
      alert(t("comment_submission_failed"));
    }
  };

  const conditions = ["Glaucoma", "AMD", "DR"]; // Labels for toggles

  return (
    <>
      <Grid
        container
        item
        xs={10.5}
        sm={11}
        pt={isWiderThanSmallScreen ? 1 : 0}
        pb={isWiderThanSmallScreen ? 1 : 0}
      >
        <Grid item xs={12} sm={6.5} display="flex" alignItems="center">
          <Typography
            variant={isWiderThanSmallScreen ? "h6" : "body1"}
            noWrap
            fontWeight="bold"
            fontSize={shouldReduceFontSizeInPdf ? 18 : undefined}
          >
            {name}
          </Typography>
          {!forBackendPdf && (
            <AddCommentButton
              isWiderThanSmallScreen={isWiderThanSmallScreen}
              handleClickOpen={handleOpen}
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={5.5}
          display="flex"
          alignItems="center"
          justifyContent={isWiderThanSmallScreen ? "flex-end" : "flex-start"}
          textAlign={isWiderThanSmallScreen ? "right" : "left"}
        >
          <Typography
            variant="body1"
            fontSize={shouldReduceFontSizeInPdf ? 14 : dynamicFontSize}
            mr={1}
            noWrap
          >
            {rightText}
          </Typography>
        </Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          sx: {
            width: { xs: "80%", sm: "70%", md: "480px" },
            margin: "auto",
          },
        }}
      >
        <DialogTitle id="form-dialog-title" sx={{ fontWeight: "bold" }}>
          {t("add_comment")}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                value={commentValue}
                autoFocus
                margin="dense"
                label={t("comment_text")}
                type="text"
                fullWidth
                multiline
                minRows={4}
                maxRows={4}
                color="warning"
                inputProps={{ maxLength }}
                onChange={handleChange}
              />
              <Typography
                textAlign="right"
                color={commentValue?.length === maxLength ? "red" : "inherit"}
              >
                {`${commentValue?.length ?? 0}/${maxLength}`}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormGroup>
                {conditions.map((condition) => (
                  <FormControlLabel
                    key={condition}
                    label={<Typography>{condition}</Typography>}
                    labelPlacement="start"
                    control={
                      <Switch
                        checked={toggleState[condition]}
                        onChange={() => handleToggleChange(condition)}
                        name={condition}
                        color="warning"
                      />
                    }
                  />
                ))}
              </FormGroup>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={ModalCustomCancel}>
            {t("button_cancel")}
          </Button>
          <Button
            variant="outlined"
            onClick={handleSubmit}
            sx={{ ...ModalCustomAdd(), fontSize: 15 }}
          >
            {t("button_add")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReportInfoHeaderComponentAnalysis;
