// Library methods
import {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useContext,
} from "react";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { throttle } from "lodash";

// MUI Components
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// Components
import AddExamSlider from "./AddExamSlider";
import DeleteExamModal from "./DeleteExamModal";
import DuplicateExamModal from "./DuplicateExamModal";
import ExamCard from "./ExamCard";
import LinkDeviceModal from "../../../components/UI/LinkDeviceModal";
import UnavailableDeviceModal from "../../../components/UI/UnavailableDeviceModal";
import PatientInfoSection from "./PatientInfoSection";
import ListHeader from "../../../components/UI/ListHeader";
import PaginationWrapper from "../../../components/UI/PaginationWrapper";
import PageIntro from "../../../components/UI/PageIntro";
import SnackbarMessage from "../../../components/UI/SnackbarMessage";
import Loader from "../../../components/UI/Loader";

// Utilities
import { linkExamToDevice } from "../../../services/Device";
import {
  addExam as examAdd,
  addExamSection,
  deleteExam,
  deleteExamSection,
  getExamSectionById,
  updateExam,
  updateExamSection,
  getProgressAnalysisStatus,
} from "../../../services/Exam";
import { getComparator } from "../../../utils/tableHelper";
import { itemsRendering, pagesCount } from "../../../utils/paginationHelper";
import { formatDateForMuiX } from "../../../utils/dateHelper";
import {
  getVisualField,
  getExamType,
  GridTypes,
  FixationLossMethodOptions,
  FixationPointShapeOptions,
} from "../../../utils/examReportsHelper";
import useClinic from "../../../hooks/useClinic";
import { useDevices } from "../../../hooks/useDevices";

// Assets
import addExamArrowIcon from "../../../assets/images/arrow/add-exam-blank.svg";
import { useWindowDimensions } from "../../../contexts/WindowSizeContext";
import EditExamSlider from "./EditExamSlider";
import {
  AlgorithmContext,
  ExamTypeContext,
  EyeContext,
} from "../../../contexts/ExamPropertyContext";
import { useExamStatus } from "../../../hooks/useExamStatus";
import { Button } from "@mui/material";
import { ModalCustomCancel } from "../../../styles/muiStylesHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ExamTransferModal from "../../../components/UI/ExamTransferModal";
import { ToastContext } from "../../../contexts/ToastContext";
import ProgressAnalysis from "./ShowProgressAnalysis";
import SharedTabs from "../../../components/UI/SharedTabs";
import ActionableSearchBar from "../../../components/UI/ActionableSearchBar";
import ProgressionAnalysisList from "../ProgressionAnalysisList";
import { hideLoader, showLoader } from "../../../components/loader/loader";

const ExamsList = ({
  connection,
  isLoading,
  selectedPatients,
  patients,
  patient,
  patientId,
  patientName,
  rows,
  setRows,
  devicesOptions,
  refetchDevices,
  refetchExams,
  refetchPatients,
  devicesWithExams,
  handleViewReport,
  page,
  setPage,
  heightExceptListsContainer,
  isLoadingSignalR,
}) => {
  // clinic context
  const { clinicId, clinicSettings } = useClinic();

  // internationalization
  const { t } = useTranslation();

  // auth0
  const { getAccessTokenSilently } = useAuth0();

  const { unlinkDeviceModal, handleUnlinkDeviceClick } = useDevices(
    connection,
    clinicId,
    patients,
    devicesWithExams
  );

  const { getExamStatus } = useExamStatus();

  const {
    typeVisualField,
    typeColorVision,
    typeContrastSensitivity,
    TYPE_VISUAL_ACUITY,
    path,
    sectionsPath,
  } = useContext(ExamTypeContext);
  const { eyeLeft, eyeRight, eyeBoth } = useContext(EyeContext);
  const {
    esterman,
    full120,
    SCREEN_FAST,
    FAST_THRESHOLD,
    STANDARD_THRESHOLD,
    FULL_THRESHOLD,
  } = useContext(AlgorithmContext);

  const { toast, setToast } = useContext(ToastContext);

  // states init
  const [order, setOrder] = useState("desc");
  const [orderByOptions, setOrderByOptions] = useState(["creationDate"]);
  const [selected, setSelected] = useState([]);
  const [selectedFull, setSelectedFull] = useState([]);
  const [selectedExam, setSelectedExam] = useState("");
  const [selectedDuplicateExam, setSelectedDuplicateExam] = useState("");
  const [editExamDevice, setEditExamDevice] = useState({ name: "", val: "" });

  // form field states init
  const [editExamId, setEditExamId] = useState("");
  const [examType, setExamType] = useState("");
  const [examFieldType, setExamFieldType] = useState("visual-field");
  const [encouragement, setEncouragement] = useState(false);
  const [autoPause, setAutoPause] = useState(false);
  const [requiresTutorial, setRequiresTutorial] = useState(false);
  const [visualGrid, setVisualGrid] = useState("");
  const [scheduledDate, setScheduledDate] = useState(new Date());
  const [scheduledError, setScheduledError] = useState(0);
  const [binocular, setBinocular] = useState(true);
  const [leftCheckBox, setLeftCheckBox] = useState(false);
  const [rightCheckBox, setRightCheckBox] = useState(false);
  const [position, setPosition] = useState(false); // Visual Acuity
  const [sectionOrder, setSectionOrder] = useState([0, 1]); // 0,3,2,1

  const sectionId = useRef("");
  const leftSectionId = useRef("");
  const rightSectionId = useRef("");

  const [stimulusSizeType, setStimulusSizeType] = useState("");
  const [fixationType, setFixationType] = useState({
    fixationPointShapeType: FixationPointShapeOptions.Sphere,
    fixationColorType: "Turquoise",
    fixationLossMethod: FixationLossMethodOptions.EyeTracking,
  });
  const [calibration, setCalibration] = useState(false);
  const [unlimitedTime, setUnlimitedTime] = useState(false);

  // left distance prescription fields
  const [leftDpSphere, setLeftDpSphere] = useState("");
  const [leftDpCylinder, setLeftDpCylinder] = useState("");
  const [leftDpAxis, setLeftDpAxis] = useState("");

  // right distance prescription fields
  const [rightDpSphere, setRightDpSphere] = useState("");
  const [rightDpCylinder, setRightDpCylinder] = useState("");
  const [rightDpAxis, setRightDpAxis] = useState("");

  // left liquid trail fields
  const [leftLtlSphere, setLeftLtlSphere] = useState("");
  const [leftLtlCylinder, setLeftLtlCylinder] = useState("");
  const [leftLtlAxis, setLeftLtlAxis] = useState("");

  // right liquid trail fields
  const [rightLtlSphere, setRightLtlSphere] = useState("");
  const [rightLtlCylinder, setRightLtlCylinder] = useState("");
  const [rightLtlAxis, setRightLtlAxis] = useState("");

  // state for add and delete exams modals
  const [addModal, setAddModal] = useState(false);
  const [progressAnalysis, setShowProgressAnalysis] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [duplicateModal, setDuplicateModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [linkDeviceModal, setLinkDeviceModal] = useState(false);

  // State to manage button's disabled status
  const [buttonDisabled, setButtonDisabled] = useState(false);

  // loading states
  const [linkLoading, setLinkLoading] = useState(false);

  // state to check if unavailableDeviceModal should be displayed or not
  const [unavailableDeviceModal, setUnavailableDeviceModal] = useState(false);

  // state for the search bar
  const [searchTerm, setSearchTerm] = useState("");

  // check if there are more than one patients currently selected
  const isMoreThanOneSelected = selectedPatients.length > 1;

  const [maxNumOfRows, setMaxNumOfRows] = useState(1);
  const examCardRef = useRef(null);
  const [examCardHeight, setExamCardHeight] = useState(0);
  const { height } = useWindowDimensions();

  const [shouldDelete, setShouldDelete] = useState(false);

  const [value, setValue] = useState(0);

  const [progressionAnalysisFound, setProgressionAnalysisFound] =
    useState(false);

  const orderByTypeAlg = ["type", "algorithm", "gridType"];

  // head rows
  const headCells = [
    // This one is displayed before the others, on a different grid.
    {
      id: "device",
      label: t("exams_column_device"),
      gridSize: 1.5,
      orderUsing: [],
    },
    // The below ones are displayed within the same grid, and should sum to 11 (that will be displayed together with a checkbox of grid size 1).
    {
      id: "status",
      label: t("exams_column_status"),
      gridSize: 2.5,
      orderUsing: ["statusOrder", ...orderByTypeAlg],
    },
    {
      id: "eye",
      label: t("exams_column_eye"),
      gridSize: 2.5,
      orderUsing: ["eyesOrder", ...orderByTypeAlg],
    },
    {
      id: "type",
      label: t("exams_column_type"),
      gridSize: 4.5,
      orderUsing: [...orderByTypeAlg, scheduledDate],
    },
    {
      id: "scheduledDate",
      label: t("exams_column_scheduled_date"),
      gridSize: 2.5,
      orderUsing: ["scheduledDate", ...orderByTypeAlg],
    },
  ];
  const extraCells = [
    {
      id: "manage",
      label: "",
      gridSize: 2,
    },
  ];

  // reset selected patients when patients selection changes
  useEffect(() => {
    setSelected([]);
    setSelectedFull([]);
  }, [selectedPatients]);

  // handles the device linking when there is only one device option
  const handleLinkDeviceWithoutModal = useCallback(
    async (examId, deviceId) => {
      try {
        // get token
        const token = await getAccessTokenSilently();
        const examData = { examId };
        await linkExamToDevice(token, clinicId, deviceId, examData);

        setSelectedExam("");
        setToast(() => ({
          success: true,
          message: t("device_link_success"),
        }));
      } catch (error) {
        setSelectedExam("");
        setToast(() => ({
          success: false,
          message: t("device_link_error"),
        }));
      }
    },
    [getAccessTokenSilently, clinicId, setToast, t]
  );

  // handles the link device modal confirmation
  const handleLinkDevice = useCallback(
    async (id) => {
      // close the unlink Modal
      setLinkDeviceModal(false);
      setLinkLoading(true);
      // link
      try {
        // get token
        const token = await getAccessTokenSilently();
        const examData = { examId: selectedExam.id ?? id };
        await linkExamToDevice(token, clinicId, editExamDevice.val, examData);

        setSelectedExam("");
        setToast(() => ({
          success: true,
          message: t("device_link_success"),
        }));
        setTimeout(() => {
          setLinkLoading(false);
        }, 2000);
      } catch (error) {
        setSelectedExam("");
        setToast(() => ({
          success: false,
          message: t("device_link_error"),
        }));
        setLinkLoading(false);
      }
    },
    [
      getAccessTokenSilently,
      selectedExam.id,
      clinicId,
      editExamDevice,
      setToast,
      t,
    ]
  );

  const addVfSection = useCallback(
    async (token, newExamId, section, eye, visualFieldSections) => {
      const eyeSection = {
        algorithm: section.algorithm,
        gridType: section.gridType,
        eye: eye,
        distanceSphere: section.distanceSphere ?? "-1.0",
        distanceCylinder: section.distanceCylinder ?? "-1.0",
        distanceAxis: section.distanceAxis ?? "-1.0",
        trialSphere: section.trialSphere ?? "-1.0",
        trialCylinder: section.trialCylinder ?? "-1.0",
        trialAxis: section.trialAxis ?? "-1.0",
        stimulusSizeType: section.stimulusSizeType
          ? section.stimulusSizeType
          : "III",
        fixationPointShapeType:
          section.fixationPointShapeType ?? FixationPointShapeOptions.Sphere,
        fixationLossMethodType:
          section?.fixationLossMethodType ??
          FixationLossMethodOptions.EyeTracking,
        order: section?.order ?? 0,
      };

      // add to api
      try {
        const newEyeSection = await addExamSection(
          token,
          clinicId,
          patientId,
          newExamId,
          eyeSection,
          section.algorithm === full120
            ? path[typeVisualField]
            : examFieldType
              ? examFieldType
              : path[typeVisualField]
        );
        visualFieldSections.push(newEyeSection);
      } catch (error) {
        //console.log(error);
      }
    },
    [clinicId, examFieldType, full120, path, patientId, typeVisualField]
  );

  const addSection = useCallback(
    async (eyeSection, examId, token) => {
      try {
        const sections = [];
        // add to api
        if (binocular) {
          const newSection = await addExamSection(
            token,
            clinicId,
            patientId,
            examId,
            eyeSection,
            examFieldType
          );
          sections.push(newSection);
        } else {
          if (leftCheckBox) {
            eyeSection.eye = eyeLeft;
            eyeSection.order = sectionOrder?.[1];
            const newSection = await addExamSection(
              token,
              clinicId,
              patientId,
              examId,
              eyeSection,
              examFieldType
            );
            sections.push(newSection);
          }
          if (rightCheckBox) {
            eyeSection.eye = eyeRight;
            eyeSection.order = sectionOrder?.[0];
            const newSection = await addExamSection(
              token,
              clinicId,
              patientId,
              examId,
              eyeSection,
              examFieldType
            );
            sections.push(newSection);
          }
        }
        return sections;
      } catch (e) {}
    },
    [
      binocular,
      clinicId,
      examFieldType,
      eyeLeft,
      eyeRight,
      leftCheckBox,
      patientId,
      rightCheckBox,
      sectionOrder,
    ]
  );

  // add an exam
  const addExam = useCallback(async () => {
    let examData = {};
    if (
      examFieldType === path[typeVisualField] ||
      examFieldType === path[esterman] ||
      examFieldType === path[full120]
    ) {
      examData = {
        scheduledDate: formatDateForMuiX(scheduledDate),
        requiresTutorial: requiresTutorial,
        fixationColorType: fixationType?.fixationColorType ?? "Turquoise",
        autoPause: autoPause ?? true,
        encouragement: encouragement ?? true,
        calibration: calibration,
      };
    } else {
      examData = {
        scheduledDate: formatDateForMuiX(scheduledDate),
        requiresTutorial: requiresTutorial,
        fixationColorType: fixationType?.fixationColorType ?? "Turquoise",
        calibration: false,
      };
    }

    // close modal
    setAddModal(false);

    // add exam to exams api
    let token = "";
    let newExam = null;

    try {
      // get token
      token = await getAccessTokenSilently();
      newExam = await examAdd(token, clinicId, patientId, examData);
    } catch (error) {
      setToast(() => ({
        success: false,
        message: t("exams_add_error"),
      }));
    }

    if (
      examFieldType === path[typeVisualField] ||
      examFieldType === path[full120]
    ) {
      // sections array to store both sections for the table
      let visualFieldSections = [];
      // if left eye is enabled, create section
      if (leftCheckBox) {
        const leftEyeSection = {
          algorithm: examType,
          gridType: visualGrid,
          eye: eyeLeft,
          order: sectionOrder?.[1],
          distanceSphere: leftDpSphere ? leftDpSphere : "-1.0",
          distanceCylinder: leftDpCylinder ? leftDpCylinder : "-1.0",
          distanceAxis: leftDpAxis ? leftDpAxis : "-1.0",
          trialSphere: leftLtlSphere ? leftLtlSphere : "-1.0",
          trialCylinder: leftLtlCylinder ? leftLtlCylinder : "-1.0",
          trialAxis: leftLtlAxis ? leftLtlAxis : "-1.0",
          stimulusSizeType: stimulusSizeType ? stimulusSizeType : "III",
          fixationPointShapeType:
            fixationType.fixationPointShapeType ??
            FixationPointShapeOptions.Sphere,
          fixationLossMethodType: fixationType.fixationLossMethod,
        };

        // add to api
        await addVfSection(
          token,
          newExam.id,
          leftEyeSection,
          eyeLeft,
          visualFieldSections
        );
      }

      // if right eye is enabled, create section
      if (rightCheckBox) {
        const rightEyeSection = {
          algorithm: examType,
          gridType: visualGrid,
          eye: eyeRight,
          order: sectionOrder?.[0],
          distanceSphere: rightDpSphere ? rightDpSphere : "-1.0",
          distanceCylinder: rightDpCylinder ? rightDpCylinder : "-1.0",
          distanceAxis: rightDpAxis ? rightDpAxis : "-1.0",
          trialSphere: rightLtlSphere ? rightLtlSphere : "-1.0",
          trialCylinder: rightLtlCylinder ? rightLtlCylinder : "-1.0",
          trialAxis: rightLtlAxis ? rightLtlAxis : "-1.0",
          stimulusSizeType: stimulusSizeType ? stimulusSizeType : "III",
          fixationPointShapeType:
            fixationType.fixationPointShapeType ??
            FixationPointShapeOptions.Sphere,
          fixationLossMethodType:
            fixationType.fixationLossMethod ??
            FixationLossMethodOptions.EyeTracking,
        };

        // add to api
        await addVfSection(
          token,
          newExam.id,
          rightEyeSection,
          eyeRight,
          visualFieldSections
        );
      }

      newExam.visualFieldSections = visualFieldSections;
    } else if (examFieldType === path[esterman]) {
      const eyeSection = {
        algorithm: examType,
        eye: "Both",
        gridType: visualGrid,
        fixationPointShapeType:
          fixationType.fixationPointShapeType ??
          FixationPointShapeOptions.Sphere,
        fixationLossMethodType:
          fixationType.fixationLossMethod ??
          FixationLossMethodOptions.EyeTracking,
      };

      try {
        const newSection = await addExamSection(
          token,
          clinicId,
          patientId,
          newExam.id,
          eyeSection,
          path[typeVisualField]
        );
        newExam.visualFieldSections = [newSection];
      } catch (error) {
        //console.log(error);
      }
    } else {
      const eyeSection = {
        algorithm: examType,
        eye: "Both",
        ...(examFieldType === path[typeColorVision]
          ? {
              timeLimit: unlimitedTime
                ? -1
                : clinicSettings.colorVisionDefaultTimeLimit ?? 120,
            }
          : {}),
      };
      if (examFieldType === path[TYPE_VISUAL_ACUITY]) {
        eyeSection.position = position;
      }

      try {
        const sections = await addSection(eyeSection, newExam.id, token);
        if (examFieldType === path[typeContrastSensitivity]) {
          newExam.contrastSensitivitySections = sections;
        } else if (examFieldType === path[typeColorVision]) {
          newExam.colorVisionSections = sections;
        } else if (examFieldType === path[TYPE_VISUAL_ACUITY]) {
          newExam.visualAcuitySections = sections;
        }
      } catch (e) {
        setToast(() => ({
          success: false,
          message: t("exams_add_error"),
        }));
      }
    }

    refetchPatients();
    setToast(() => ({
      success: true,
      message: t("exams_add_success"),
    }));

    // add to table
    const newRows = [...rows];
    newRows.unshift(newExam);
    setRows(newRows);

    //link exam to device (if selected)
    if (editExamDevice.val !== "") {
      handleLinkDevice(newExam?.id);
    }

    // clear form
    clearForm();
  }, [
    examFieldType,
    path,
    typeVisualField,
    esterman,
    full120,
    rows,
    setRows,
    editExamDevice,
    scheduledDate,
    requiresTutorial,
    fixationType?.fixationColorType,
    fixationType?.fixationPointShapeType,
    fixationType?.fixationLossMethod,
    autoPause,
    encouragement,
    calibration,
    getAccessTokenSilently,
    clinicId,
    patientId,
    leftCheckBox,
    rightCheckBox,
    examType,
    visualGrid,
    eyeLeft,
    sectionOrder,
    leftDpSphere,
    leftDpCylinder,
    leftDpAxis,
    leftLtlSphere,
    leftLtlCylinder,
    leftLtlAxis,
    stimulusSizeType,
    addVfSection,
    eyeRight,
    rightDpSphere,
    rightDpCylinder,
    rightDpAxis,
    rightLtlSphere,
    rightLtlCylinder,
    rightLtlAxis,
    typeColorVision,
    unlimitedTime,
    clinicSettings?.colorVisionDefaultTimeLimit,
    TYPE_VISUAL_ACUITY,
    addSection,
    typeContrastSensitivity,
    position,
    handleLinkDevice,
    refetchPatients,
    t,
    setToast,
  ]);

  const addDefaultExam = useCallback(
    async (data, token) => {
      try {
        const examData = {
          scheduledDate: formatDateForMuiX(new Date()),
          requiresTutorial: data.visualFieldDefaultRequiresTutorial,
          fixationColorType: fixationType?.fixationColorType
            ? fixationType.fixationColorType
            : "Turquoise",
          autoPause: data.visualFieldDefaultAutoPause ?? true,
          encouragement: data.visualFieldDefaultEncouragement ?? true,
          calibration: data.visualFieldDefaultCalibration,
        };

        const newExam = await examAdd(token, clinicId, patientId, examData);
        return newExam;
      } catch (e) {}
    },
    [clinicId, fixationType?.fixationColorType, patientId]
  );

  const duplicateExam = useCallback(async () => {
    setDuplicateModal(false);
    const exam = selectedDuplicateExam;

    let examData = {};

    if (
      examFieldType === path[typeVisualField] ||
      examFieldType === path[esterman] ||
      examFieldType === path[full120]
    ) {
      examData = {
        scheduledDate: formatDateForMuiX(new Date()),
        requiresTutorial: exam?.requiresTutorial,
        fixationColorType: exam?.fixationColorType
          ? fixationType?.fixationColorType
          : "Turquoise",
        autoPause: exam?.autoPause ?? true,
        encouragement: exam?.encouragement ?? true,
        calibration: exam?.calibration,
      };
    } else {
      examData = {
        scheduledDate: formatDateForMuiX(new Date()),
        requiresTutorial: exam?.requiresTutorial,
        fixationColorType: exam?.fixationColorType
          ? fixationType?.fixationColorType
          : "Turquoise",
        calibration: false,
      };
    }

    // add exam to exams api
    let token = "";
    let newExam = null;

    try {
      // get token
      token = await getAccessTokenSilently();
      newExam = await examAdd(token, clinicId, patientId, examData);
    } catch (error) {
      setToast(() => ({
        success: false,
        message: t("exams_add_error"),
      }));
      setSelectedDuplicateExam("");
    }
    // sections array to store both sections for the table
    let visualFieldSections = [];

    if (
      examFieldType === path[typeVisualField] ||
      examFieldType === path[full120]
    ) {
      // if left eye is enabled, create section
      if (exam?.visualFieldSections?.[0]) {
        const leftSection = exam?.visualFieldSections?.[0];
        await addVfSection(
          token,
          newExam.id,
          leftSection,
          leftSection.eye,
          visualFieldSections
        );
      }
      // if right eye is enabled, create section
      if (
        exam?.visualFieldSections?.[1] &&
        (exam?.eyesOrder?.includes(eyeRight) ||
          exam.visualFieldSections[1]?.eye === eyeRight)
      ) {
        const rightSection = exam?.visualFieldSections?.[1];
        await addVfSection(
          token,
          newExam.id,
          rightSection,
          eyeRight,
          visualFieldSections
        );
      }

      // setting sections attribute in newRow
      if (visualFieldSections.length > 0) {
        newExam.visualFieldSections = visualFieldSections;
      }
    } else if (examFieldType === path[esterman]) {
      const eyeSection = {
        algorithm: exam?.visualFieldSections?.[0].algorithm,
        eye: "Both",
        gridType: exam?.visualFieldSections?.[0].gridType,
        fixationPointShapeType:
          exam?.visualFieldSections?.[0]?.fixationPointShapeType ??
          FixationPointShapeOptions.Sphere,
        fixationLossMethodType:
          fixationType.fixationLossMethod ??
          FixationLossMethodOptions.EyeTracking,
      };

      try {
        const newSection = await addExamSection(
          token,
          clinicId,
          patientId,
          newExam.id,
          eyeSection,
          path[typeVisualField]
        );
        visualFieldSections.push(newSection);
      } catch (error) {
        //console.log(error);
      }
      // setting sections attribute in newRow
      if (visualFieldSections.length > 0) {
        newExam.visualFieldSections = visualFieldSections;
      }
    } else {
      const examSectionsKeys = Object.keys(exam).filter((key) =>
        key.includes("Sections")
      );
      const currentExamSectionsKey = examSectionsKeys?.filter(
        (key) => exam[key].length > 0
      );
      for (const sectionKey of currentExamSectionsKey) {
        const newSections = [];
        try {
          for (const section of exam[sectionKey]) {
            const newSection = await addExamSection(
              token,
              clinicId,
              patientId,
              newExam.id,
              section,
              examFieldType
            );
            newSections.push(newSection);
          }
          newExam[sectionKey] = newSections;
        } catch (error) {
          console.error(`Error processing section ${sectionKey}:`, error);
        }
      }
    }
    setToast(() => ({
      success: true,
      message: t("exams_add_success"),
    }));
    setSelectedDuplicateExam("");

    // add to table
    const newRows = [...rows];
    newRows.unshift(newExam);
    setRows(newRows);

    // clear form
    clearForm();
  }, [
    selectedDuplicateExam,
    examFieldType,
    path,
    typeVisualField,
    esterman,
    full120,
    rows,
    setRows,
    fixationType?.fixationColorType,
    fixationType?.fixationLossMethod,
    getAccessTokenSilently,
    clinicId,
    patientId,
    eyeRight,
    addVfSection,
    t,
    setToast,
  ]);

  // clear add form
  const clearForm = () => {
    // clear all 17 states
    setVisualGrid("");
    setExamType("");
    setExamFieldType("");
    setScheduledDate(new Date());
    setRequiresTutorial("");
    setLeftCheckBox(false);
    setRightCheckBox(false);
    setRightDpAxis("");
    setRightDpCylinder("");
    setRightDpSphere("");
    setRightLtlAxis("");
    setRightLtlCylinder("");
    setRightLtlSphere("");
    setLeftDpAxis("");
    setLeftDpCylinder("");
    setLeftDpSphere("");
    setLeftLtlAxis("");
    setLeftLtlSphere("");
    setLeftLtlCylinder("");
    setStimulusSizeType("");
    setFixationType({
      fixationPointShapeType: null,
      fixationColorType: null,
      fixationLossMethod: null,
    });
    setEditExamDevice({ name: "", val: "" });
    setCalibration(false);
    setUnlimitedTime(false);
    setAutoPause(false);
    setEncouragement(false);
    setRequiresTutorial(false);
    setSectionOrder([0, 1]);
    setScheduledError(false);
    setBinocular(true);
    sectionId.current = "";
    rightSectionId.current = "";
    leftSectionId.current = "";
  };

  // cancel edit exam modal
  const cancelEdit = () => {
    // close modal
    setEditModal(false);

    // clear form
    clearForm();
  };

  // cancel add exam modal
  const cancelAdd = () => {
    // close modal
    setAddModal(false);

    // clear form
    clearForm();
  };

  const isNotNull = (value) => {
    return value !== undefined && value !== null && value !== "Undefined";
  };

  const isNotNullEyeValue = (value) => {
    return value !== undefined && value !== null && value !== "-1";
  };

  // edit an exam
  const editExam = useCallback(async () => {
    let examData = {};
    if (
      examFieldType === path[typeVisualField] ||
      examFieldType === path[esterman] ||
      examFieldType === path[full120]
    ) {
      examData = {
        scheduledDate: formatDateForMuiX(new Date(scheduledDate)),
        requiresTutorial: requiresTutorial,
        fixationColorType: fixationType?.fixationColorType ?? "Turquoise",
        autoPause: autoPause ?? true,
        encouragement: encouragement ?? true,
        calibration: calibration,
      };
    } else {
      examData = {
        scheduledDate: formatDateForMuiX(new Date(scheduledDate)),
        requiresTutorial: requiresTutorial,
        fixationColorType: fixationType?.fixationColorType ?? "Turquoise",
        calibration: false,
      };
    }

    setButtonDisabled(true);
    // send new data to api
    let token = "";

    try {
      // get token
      token = await getAccessTokenSilently();
      await updateExam(token, clinicId, patientId, editExamId, examData);
    } catch (error) {
      setToast(() => ({
        success: true,
        message: t("exams_edit_error"),
      }));
    }

    // sections array to store both sections for the table
    // let visualFieldSections = [];

    const examSectionStatus =
      (selectedExam?.visualFieldSections?.length > 0 &&
        selectedExam?.visualFieldSections[0].algorithm === "ESTERMAN_FAST" &&
        examFieldType === path[esterman]) ||
      (selectedExam?.visualFieldSections?.length > 0 &&
        selectedExam?.visualFieldSections[0].algorithm === full120 &&
        examFieldType === path[full120]) ||
      (selectedExam?.visualFieldSections?.length > 0 &&
        selectedExam?.visualFieldSections[0].algorithm !== "ESTERMAN_FAST" &&
        selectedExam?.visualFieldSections[0].algorithm !== full120 &&
        examFieldType === path[typeVisualField]) ||
      ((selectedExam?.type === typeContrastSensitivity ||
        selectedExam?.contrastSensitivitySections?.length > 0) &&
        examFieldType === path[typeContrastSensitivity] &&
        ((selectedExam.contrastSensitivitySections[0]?.eye === eyeBoth &&
          binocular) ||
          (selectedExam.contrastSensitivitySections[0]?.eye !== eyeBoth &&
            !binocular))) ||
      ((selectedExam?.type === typeColorVision ||
        selectedExam?.colorVisionSections?.length > 0) &&
        examFieldType === path[typeColorVision] &&
        ((selectedExam.colorVisionSections[0]?.eye === eyeBoth && binocular) ||
          (selectedExam.colorVisionSections[0]?.eye !== eyeBoth &&
            !binocular))) ||
      ((selectedExam?.type === TYPE_VISUAL_ACUITY ||
        selectedExam?.visualAcuitySections?.length > 0) &&
        examFieldType === path[TYPE_VISUAL_ACUITY] &&
        ((selectedExam.visualAcuitySections[0]?.eye === eyeBoth && binocular) ||
          (selectedExam.visualAcuitySections[0]?.eye !== eyeBoth &&
            !binocular)));

    const examSectionsKeys = Object.keys(selectedExam).filter((key) =>
      key.includes("Sections")
    );
    const currentExamSectionsKey = examSectionsKeys.filter(
      (key) => selectedExam[key].length > 0
    );
    if (currentExamSectionsKey.length !== 1) return;

    //if examSectionStatus === false, delete exam section
    if (!examSectionStatus) {
      const deleteAllSections = async () => {
        // Delete sections
        for (const section of selectedExam[currentExamSectionsKey]) {
          await deleteExamSection(
            token,
            clinicId,
            patientId,
            editExamId,
            section.id,
            sectionsPath[currentExamSectionsKey]
          );
          if (section?.eye === eyeRight) rightSectionId.current = "";
          else if (section?.eye === eyeLeft) leftSectionId.current = "";
          else {
            rightSectionId.current = "";
            leftSectionId.current = "";
          }
        }
      };
      await deleteAllSections();
    }

    // if (examFieldType === "visual-field") {
    if (
      examFieldType === path[typeVisualField] ||
      examFieldType === path[full120] ||
      ((examFieldType === path[typeColorVision] ||
        examFieldType === path[typeContrastSensitivity] ||
        examFieldType === path[TYPE_VISUAL_ACUITY]) &&
        !binocular)
    ) {
      const examPath =
        examFieldType === path[full120] ? path[typeVisualField] : examFieldType;
      try {
        // delete in api if unchecked
        if (leftCheckBox === false && leftSectionId.current !== "") {
          const deleteLeftSection = async () => {
            await deleteExamSection(
              token,
              clinicId,
              patientId,
              editExamId,
              leftSectionId.current,
              examPath
            );
            leftSectionId.current = "";
          };
          deleteLeftSection();
        }
        if (rightCheckBox === false && rightSectionId.current !== "") {
          const deleteRightSection = async () => {
            await deleteExamSection(
              token,
              clinicId,
              patientId,
              editExamId,
              rightSectionId.current,
              examPath
            );
            rightSectionId.current = "";
          };
          deleteRightSection();
        }
      } catch (error) {
        console.log(error);
      }
      // if right eye is enabled, create section
      if (rightCheckBox) {
        const rightEyeSection =
          examFieldType === path[typeVisualField] ||
          examFieldType === path[full120]
            ? {
                algorithm: examType,
                gridType: visualGrid,
                eye: eyeRight,
                distanceSphere: rightDpSphere ? rightDpSphere : "-1.0",
                distanceCylinder: rightDpCylinder ? rightDpCylinder : "-1.0",
                distanceAxis: rightDpAxis ? rightDpAxis : "-1.0",
                trialSphere: rightLtlSphere ? rightLtlSphere : "-1.0",
                trialCylinder: rightLtlCylinder ? rightLtlCylinder : "-1.0",
                trialAxis: rightLtlAxis ? rightLtlAxis : "-1.0",
                stimulusSizeType: stimulusSizeType ? stimulusSizeType : "III",
                fixationPointShapeType:
                  fixationType.fixationPointShapeType ??
                  FixationPointShapeOptions.Sphere,
                fixationLossMethodType:
                  fixationType.fixationLossMethod ??
                  FixationLossMethodOptions.EyeTracking,
                order: sectionOrder?.[0],
              }
            : {
                algorithm: examType,
                eye: eyeRight,
                order: sectionOrder?.[0],
                ...(examFieldType === path[typeColorVision]
                  ? {
                      timeLimit: unlimitedTime
                        ? -1
                        : clinicSettings?.colorVisionDefaultTimeLimit ?? 120,
                    }
                  : {}),
              };
        if (examFieldType === path[TYPE_VISUAL_ACUITY]) {
          rightEyeSection.position = position;
        }

        // add to api
        try {
          if (rightSectionId.current === "") {
            await addExamSection(
              token,
              clinicId,
              patientId,
              editExamId,
              rightEyeSection,
              examPath
            );
          } else {
            await updateExamSection(
              token,
              clinicId,
              patientId,
              editExamId,
              rightSectionId.current,
              rightEyeSection,
              examPath
            );
            await getExamSectionById(
              token,
              clinicId,
              patientId,
              editExamId,
              rightSectionId.current,
              examPath
            );
          }
        } catch (error) {
          //console.log(error);
        }
      }

      // if left eye is enabled, create section
      if (leftCheckBox) {
        const leftEyeSection =
          examFieldType === path[typeVisualField] ||
          examFieldType === path[full120]
            ? {
                algorithm: examType,
                gridType: visualGrid,
                eye: eyeLeft,
                distanceSphere: leftDpSphere ? leftDpSphere : "-1.0",
                distanceCylinder: leftDpCylinder ? leftDpCylinder : "-1.0",
                distanceAxis: leftDpAxis ? leftDpAxis : "-1.0",
                trialSphere: leftLtlSphere ? leftLtlSphere : "-1.0",
                trialCylinder: leftLtlCylinder ? leftLtlCylinder : "-1.0",
                trialAxis: leftLtlAxis ? leftLtlAxis : "-1.0",
                stimulusSizeType: stimulusSizeType ? stimulusSizeType : "III",
                fixationPointShapeType:
                  fixationType.fixationPointShapeType ??
                  FixationPointShapeOptions.Sphere,
                fixationLossMethodType:
                  fixationType.fixationLossMethod ??
                  FixationLossMethodOptions.EyeTracking,
                order: sectionOrder?.[1],
              }
            : {
                algorithm: examType,
                eye: eyeLeft,
                order: sectionOrder?.[1],
                ...(examFieldType === path[typeColorVision]
                  ? {
                      timeLimit: unlimitedTime
                        ? -1
                        : clinicSettings?.colorVisionDefaultTimeLimit ?? 120,
                    }
                  : {}),
              };
        if (examFieldType === path[TYPE_VISUAL_ACUITY]) {
          leftEyeSection.position = position;
        }

        // add to api
        try {
          if (leftSectionId.current === "") {
            await addExamSection(
              token,
              clinicId,
              patientId,
              editExamId,
              leftEyeSection,
              examPath
            );
          } else {
            await updateExamSection(
              token,
              clinicId,
              patientId,
              editExamId,
              leftSectionId.current,
              leftEyeSection,
              examPath
            );
            await getExamSectionById(
              token,
              clinicId,
              patientId,
              editExamId,
              leftSectionId.current,
              examPath
            );
          }
        } catch (error) {
          //console.log(error);
        }
      }
    } else if (examFieldType === path[esterman]) {
      const eyeSection = {
        algorithm: examType,
        gridType: visualGrid,
        eye: eyeBoth,
        fixationPointShapeType:
          fixationType.fixationPointShapeType ??
          FixationPointShapeOptions.Sphere,
        fixationLossMethodType:
          fixationType.fixationLossMethod ??
          FixationLossMethodOptions.EyeTracking,
      };
      if (!examSectionStatus) {
        await addExamSection(
          token,
          clinicId,
          patientId,
          editExamId,
          eyeSection,
          path[typeVisualField]
        );
      } else {
        await updateExamSection(
          token,
          clinicId,
          patientId,
          editExamId,
          sectionId.current,
          eyeSection,
          path[typeVisualField]
        );
      }
    } else {
      const eyeSection = {
        algorithm: examType,
        eye: "Both",
        ...(examFieldType === path[typeColorVision]
          ? {
              timeLimit: unlimitedTime
                ? -1
                : clinicSettings?.colorVisionDefaultTimeLimit ?? 120,
            }
          : {}),
      };
      if (examFieldType === path[TYPE_VISUAL_ACUITY]) {
        eyeSection.position = position;
      }

      if (!examSectionStatus) {
        await addSection(eyeSection, editExamId, token);
      } else {
        await updateExamSection(
          token,
          clinicId,
          patientId,
          editExamId,
          sectionId.current,
          eyeSection,
          examFieldType
        );
      }
    }

    if (editExamDevice.val !== "") {
      await handleLinkDevice(editExamId);
      setEditExamDevice("");
    }
    await refetchPatients();
    refetchExams();
    // close modal
    setEditModal(false);
    setToast(() => ({
      success: true,
      message: t("exams_edit_success"),
    }));

    // edit row on the table
  }, [
    examFieldType,
    path,
    typeVisualField,
    esterman,
    full120,
    selectedExam,
    typeContrastSensitivity,
    eyeBoth,
    binocular,
    typeColorVision,
    TYPE_VISUAL_ACUITY,
    editExamDevice,
    refetchExams,
    refetchPatients,
    scheduledDate,
    requiresTutorial,
    fixationType?.fixationColorType,
    fixationType?.fixationPointShapeType,
    fixationType?.fixationLossMethod,
    autoPause,
    encouragement,
    calibration,
    getAccessTokenSilently,
    clinicId,
    patientId,
    editExamId,
    sectionsPath,
    eyeRight,
    eyeLeft,
    rightCheckBox,
    leftCheckBox,
    examType,
    visualGrid,
    rightDpSphere,
    rightDpCylinder,
    rightDpAxis,
    rightLtlSphere,
    rightLtlCylinder,
    rightLtlAxis,
    stimulusSizeType,
    sectionOrder,
    leftDpSphere,
    leftDpCylinder,
    leftDpAxis,
    leftLtlSphere,
    leftLtlCylinder,
    leftLtlAxis,
    unlimitedTime,
    clinicSettings?.colorVisionDefaultTimeLimit,
    position,
    addSection,
    handleLinkDevice,
    t,
    setToast,
  ]);

  useEffect(() => {
    if (editModal) setButtonDisabled(false);
  }, [editModal]);

  // handle edit button click on patient card
  const handleEditClick = useCallback(
    (exam) => {
      clearForm();
      setSelectedExam(exam);
      if (isNotNull(exam.requiresTutorial))
        setRequiresTutorial(exam.requiresTutorial);
      if (isNotNull(exam.scheduledDate)) setScheduledDate(exam.scheduledDate);

      const autopopulate = async () => {
        setEditExamId(exam.id);
        //update exam type
        if (exam?.visualFieldSections?.length > 0) {
          setFixationType({
            fixationColorType: exam.visualFieldSections[0].fixationColorType,
            fixationPointShapeType:
              exam.visualFieldSections[0].fixationPointShapeType,
            fixationLossMethod:
              exam.visualFieldSections[0]?.fixationLossMethodType,
          });
          if (exam.visualFieldSections?.[0].algorithm === "ESTERMAN_FAST") {
            setExamFieldType(path[esterman]);
            setRightCheckBox(true);
            setLeftCheckBox(true);
            sectionId.current = exam.visualFieldSections?.[0]?.id;
            if (isNotNull(exam.visualFieldSections?.[0].algorithm))
              setExamType(exam.visualFieldSections?.[0].algorithm);
            if (isNotNull(exam.visualFieldSections?.[0].gridType))
              setVisualGrid(exam.visualFieldSections?.[0].gridType);
            if (isNotNull(exam.autoPause)) setAutoPause(exam.autoPause);
            if (isNotNull(exam.encouragement))
              setEncouragement(exam.encouragement);
            if (isNotNull(exam.calibration)) setCalibration(exam.calibration);
            setBinocular(exam.visualFieldSections[0]?.eye === eyeBoth);
          } else {
            const isFull120 =
              exam.visualFieldSections?.[0]?.algorithm === full120;
            setExamFieldType(isFull120 ? path[full120] : path[typeVisualField]);
            if (isNotNull(exam.visualFieldSections?.[0].algorithm))
              setExamType(exam.visualFieldSections?.[0].algorithm);
            if (isNotNull(exam.visualFieldSections?.[0].gridType))
              setVisualGrid(exam.visualFieldSections?.[0].gridType);
            if (isNotNull(exam.visualFieldSections?.[0].stimulusSizeType))
              setStimulusSizeType(
                exam.visualFieldSections?.[0].stimulusSizeType
              );
            if (isNotNull(exam.autoPause)) setAutoPause(exam.autoPause);
            if (isNotNull(exam.encouragement))
              setEncouragement(exam.encouragement);
            if (isNotNull(exam.calibration)) setCalibration(exam.calibration);
            if (isNotNull(exam.visualFieldSections?.[0]?.order))
              setSectionOrder(() => {
                const order = Array.from(
                  { length: exam.visualFieldSections?.length || 0 },
                  (_, index) => index
                );
                exam.visualFieldSections?.forEach(
                  (section) =>
                    (order[section?.eye === eyeRight ? 0 : 1] = section?.order)
                );
                return order;
              });
          }
        } else if (
          exam?.contrastSensitivitySections?.length ||
          exam?.colorVisionSections?.length ||
          exam?.visualAcuitySections?.length
        ) {
          setExamFieldType(
            exam?.colorVisionSections?.length
              ? path[typeColorVision]
              : exam?.visualAcuitySections?.length
                ? path[TYPE_VISUAL_ACUITY]
                : path[typeContrastSensitivity]
          );

          const examSectionsKeys = Object.keys(exam).filter((key) =>
            key.includes("Sections")
          );
          const currentExamSectionsKey = examSectionsKeys?.filter(
            (key) => exam[key].length > 0
          );
          for (const sectionKey of currentExamSectionsKey) {
            exam[sectionKey].forEach((section) => {
              if (section?.eye === eyeRight) {
                rightSectionId.current = section?.id;
                setRightCheckBox(true);
              } else if (section?.eye === eyeLeft) {
                leftSectionId.current = section?.id;
                setLeftCheckBox(true);
              }
              sectionId.current = section?.id;
              setExamType(section?.algorithm);
              setBinocular(section?.eye === eyeBoth);
              if (section?.eye === eyeBoth) {
                setRightCheckBox(true);
                setLeftCheckBox(true);
              }
              if (isNotNull(section?.position)) setPosition(section.position);
              if (isNotNull(section?.timeLimit))
                setUnlimitedTime(section?.timeLimit === -1);
              if (isNotNull(section?.order))
                setSectionOrder((prev) => {
                  const order =
                    prev ??
                    Array.from(
                      { length: exam[sectionKey].length || 0 },
                      (_, index) => index
                    );
                  order[section?.eye === eyeRight ? 0 : 1] = section?.order;
                  return order;
                });
            });
          }
          // if (isNotNull(exam.contrastSensitivitySections?.[0].algorithm))
          //   setExamType(exam.contrastSensitivitySections?.[0].algorithm);
          // setBinocular(exam.contrastSensitivitySections[0]?.eye === eyeBoth);
        }
        if (
          exam.visualFieldSections &&
          exam?.visualFieldSections?.[0]?.algorithm !== "ESTERMAN_FAST"
        ) {
          exam.visualFieldSections.forEach((section) => {
            if (section.eye === eyeRight) {
              // set checkbox
              setRightCheckBox(true);
              rightSectionId.current = section.id;

              // set all 6 fields
              if (isNotNullEyeValue(section.distanceSphere))
                setRightDpSphere(section.distanceSphere);
              if (isNotNullEyeValue(section.trialSphere))
                setRightLtlSphere(section.trialSphere);
              if (isNotNullEyeValue(section.distanceCylinder))
                setRightDpCylinder(section.distanceCylinder);
              if (isNotNullEyeValue(section.distanceAxis))
                setRightDpAxis(section.distanceAxis);
              if (isNotNullEyeValue(section.trialCylinder))
                setRightLtlCylinder(section.trialCylinder);
              if (isNotNullEyeValue(section.trialAxis))
                setRightLtlAxis(section.trialAxis);
            } else {
              // set checkbox
              setLeftCheckBox(true);
              leftSectionId.current = section.id;

              // set all 6 fields
              if (isNotNullEyeValue(section.distanceSphere))
                setLeftDpSphere(section.distanceSphere);
              if (isNotNullEyeValue(section.trialSphere))
                setLeftLtlSphere(section.trialSphere);
              if (isNotNullEyeValue(section.distanceCylinder))
                setLeftDpCylinder(section.distanceCylinder);
              if (isNotNullEyeValue(section.distanceAxis))
                setLeftDpAxis(section.distanceAxis);
              if (isNotNullEyeValue(section.trialCylinder))
                setLeftLtlCylinder(section.trialCylinder);
              if (isNotNullEyeValue(section.trialAxis))
                setLeftLtlAxis(section.trialAxis);
            }
          });
        }
      };
      autopopulate();
      setEditModal(true);
    },
    [
      TYPE_VISUAL_ACUITY,
      esterman,
      eyeBoth,
      eyeLeft,
      eyeRight,
      full120,
      path,
      typeColorVision,
      typeContrastSensitivity,
      typeVisualField,
    ]
  );

  useEffect(() => {
    const canDelete = selected.every((examId) => {
      const examData = rows.find((row) => row.id === examId);
      return examData && !examData.associatedDevice;
    });

    setShouldDelete(canDelete);
  }, [rows, selected]);

  // delete exams selected
  const deleteExams = useCallback(async () => {
    // close delete Modal
    setDeleteModal(false);

    // method to generate an exam deletion promise
    const generateDeletePromise = async (examId) => {
      // get token
      const token = await getAccessTokenSilently();
      await deleteExam(token, clinicId, patientId, examId);
    };

    // delete from api
    try {
      const deletePromises = selected.map((examId) =>
        generateDeletePromise(examId)
      );

      await Promise.all(deletePromises);

      refetchPatients();
      setToast(() => ({
        success: true,
        message: t("exams_delete_success"),
      }));
    } catch (error) {
      setToast(() => ({
        success: false,
        message: t("exams_delete_error"),
      }));
    }

    // delete from table
    const newRows = rows.filter((exam) => !selected.includes(exam.id));
    setRows([...newRows]);

    // clear selected
    setSelected([]);
    setSelectedFull([]);
  }, [
    clinicId,
    getAccessTokenSilently,
    patientId,
    rows,
    selected,
    setRows,
    setToast,
    refetchPatients,
    t,
  ]);

  // handle on close of add exam modal
  const handleAddClose = (event, reason) => {
    if (reason !== "backdropClick") {
      cancelAdd();
    }
  };

  // handle on close of delete exam modal
  const handleDeleteClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setDeleteModal(false);
    }
  };

  // handle on close of delete exam modal
  const handleDuplicateClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setDuplicateModal(false);
      setSelectedDuplicateExam("");
    }
  };

  // handle on close of link device modal
  const handleLinkDeviceClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setLinkDeviceModal(false);
    }
  };

  // handle on close of edit exam modal
  const handleEditClose = (event, reason) => {
    if (reason !== "backdropClick") {
      cancelEdit();
      // clearForm();
    }
  };

  // filter change
  const onFilterChange = useCallback(
    (event) => {
      if (page > 1) setPage(1);
      setSearchTerm(event.target.value);
    },
    [page, setPage]
  );

  // clear filter
  const clearSearchFilter = (event) => {
    setSearchTerm("");
  };

  // clear filter when selected patient changes
  useEffect(() => {
    clearSearchFilter();
  }, [patientId]);

  // pagination change
  const onPaginationChange = (event, value) => {
    setPage(value);
  };

  // handle sort Request (it can now order considering two fields)
  const handleRequestSort = (event, property) => {
    const isAsc =
      orderByOptions.length &&
      orderByOptions[0] === property[0] &&
      order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderByOptions(property);
  };

  // handles select all
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredRows.map((n) => n.id);
      setSelected(newSelecteds);
      setSelectedFull(filteredRows);
      return;
    }
    setSelected([]);
    setSelectedFull([]);
  };

  // handle checkbox clicks
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleClickToGetFullObject = (event, item) => {
    console.log(selectedFull);
    const selectedIndex = selectedFull.findIndex(
      (selectedItem) => selectedItem.id === item.id
    );
    let newSelectedFull = [];

    if (selectedIndex === -1) {
      // Add the item to the selection
      newSelectedFull = newSelectedFull.concat(selectedFull, item);
    } else if (selectedIndex === 0) {
      // Remove the first item
      newSelectedFull = newSelectedFull.concat(selectedFull.slice(1));
    } else if (selectedIndex === selectedFull.length - 1) {
      // Remove the last item
      newSelectedFull = newSelectedFull.concat(selectedFull.slice(0, -1));
    } else if (selectedIndex > 0) {
      // Remove the item at the selected index
      newSelectedFull = newSelectedFull.concat(
        selectedFull.slice(0, selectedIndex),
        selectedFull.slice(selectedIndex + 1)
      );
    }

    setSelectedFull(newSelectedFull);
  };

  const handleChangeTab = useCallback(
    (event, newValue) => setValue(newValue),
    []
  );

  // get completion text, used for filtering
  const getCompletionText = useCallback(
    (section) => {
      let val = "";
      if (section.includes(eyeLeft)) {
        val = val + t("word_left");
      }
      if (section.includes(eyeLeft) && section.includes(eyeRight)) {
        val = val + " / ";
      }
      if (section.includes(eyeRight)) {
        val = val + t("word_right");
      }
      return val;
    },
    [eyeLeft, eyeRight, t]
  );

  // refetch exams when devicesWithExams length has changed (when an exam gets completed, this happens)
  useEffect(() => {
    const controller = new AbortController();
    refetchExams(controller.signal);
    return () => {
      controller.abort();
    };
  }, [devicesWithExams?.length, refetchExams]);

  // Filtered rows
  const filteredRows = useMemo(
    () =>
      rows
        .filter(
          (row) =>
            row?.visualFieldSections?.length > 0 ||
            row?.contrastSensitivitySections?.length > 0 ||
            row?.colorVisionSections?.length > 0 ||
            row?.visualAcuitySections?.length > 0
        ) // disconsider the exams with no sections
        .filter((row) => {
          if (
            searchTerm.length &&
            row.type.toLowerCase().indexOf(searchTerm.toLowerCase()) < 0 &&
            row.scheduledDate?.slice(0, 10).indexOf(searchTerm.toLowerCase()) <
              0 &&
            getExamStatus(row, devicesWithExams)
              ?.status?.toLowerCase()
              .indexOf(searchTerm.toLowerCase()) < 0 &&
            getExamType(row).toLowerCase().indexOf(searchTerm.toLowerCase()) <
              0 &&
            getVisualField(row)
              .toLowerCase()
              .indexOf(searchTerm.toLowerCase()) < 0 &&
            getCompletionText(
              row.visualFieldSections?.map((section) => section.eye) ||
                row.contrastSensitivitySections?.map(
                  (section) => section.eye
                ) ||
                row.colorVisionSections?.map((section) => section.eye) ||
                row.visualAcuitySections?.map((section) => section.eye)
            )
              .toLowerCase()
              .indexOf(searchTerm.toLowerCase()) < 0
          )
            return false;
          return true;
        })
        .map((row) => ({
          ...row,
          // adding some helpful information to the exam
          statusOrder: getExamStatus(row, devicesWithExams)?.status,
          tutorialOrder: row.requiresTutorial ? t("word_yes") : t("word_no"),
          visualFieldOrder: getVisualField(row).toLowerCase(),
          eyesOrder: (
            row?.visualFieldSections ||
            row?.contrastSensitivitySections ||
            row?.colorVisionSections ||
            row?.visualAcuitySections
          )
            .sort((a, b) => {
              if (typeof a.eye === "string" && typeof b.eye === "string") {
                return a.eye.localeCompare(b.eye);
              }
              // or any other logic to handle non-string cases
              return 0;
            })
            .map((section) => section.eye)
            .join(""),
          deviceName: row.associatedDevice?.device?.alias || "-",
        }))
        .sort(getComparator(order, orderByOptions)),
    [
      devicesWithExams,
      getCompletionText,
      getExamStatus,
      order,
      orderByOptions,
      rows,
      searchTerm,
      t,
    ]
  );

  const availableDevicesOptions = useMemo(() => {
    if (!devicesOptions?.length) return [];
    else
      return devicesOptions.filter(
        (option) => option.webserviceCompatible === true
      );
  }, [devicesOptions]);

  const handleUnavailableDeviceClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setUnavailableDeviceModal(false);
    }
  };

  // handle link device click
  const handleLinkDeviceClick = useCallback(
    (exam) => {
      const autopopulate = async () => {
        setEditExamDevice({ name: "", val: "" });
      };
      autopopulate();
      setSelectedExam(exam);

      // in case there are no option, show unavailable modal
      if (availableDevicesOptions.length <= 0) {
        setUnavailableDeviceModal(true);
      } else if (availableDevicesOptions.length > 1) {
        // in case there are many options, show modal with available options
        setLinkDeviceModal(true);
        // } else if (availableDevicesOptions?.[0]?.webserviceCompatible) {
      } else {
        // in case there's only one device, we link it directly
        // and the device is not expired for the clinic assignment
        handleLinkDeviceWithoutModal(exam.id, availableDevicesOptions[0].id);
      }
    },
    [availableDevicesOptions, handleLinkDeviceWithoutModal]
  );

  // handle duplicate exam click
  const handleDuplicateClick = useCallback(
    (exam) => {
      setSelectedDuplicateExam(exam);
      if (exam?.visualFieldSections?.length > 0) {
        if (exam?.visualFieldSections?.[0]?.algorithm === "ESTERMAN_FAST")
          setExamFieldType(path[esterman]);
        else if (exam?.visualFieldSections?.[0]?.algorithm === full120)
          setExamFieldType(path[full120]);
        else setExamFieldType(path[typeVisualField]);
      } else if (exam?.contrastSensitivitySections?.length > 0) {
        setExamFieldType(path[typeContrastSensitivity]);
      } else if (exam?.colorVisionSections?.length > 0) {
        setExamFieldType(path[typeColorVision]);
      } else if (exam?.visualAcuitySections?.length) {
        setExamFieldType(path[TYPE_VISUAL_ACUITY]);
      }
      setDuplicateModal(true);
    },
    [
      TYPE_VISUAL_ACUITY,
      esterman,
      full120,
      path,
      typeColorVision,
      typeContrastSensitivity,
      typeVisualField,
    ]
  );

  const handleCreateDefaultExamClicked = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      if (clinicSettings) {
        const defaultEye = clinicSettings?.visualFieldDefaultEye;
        const newExam = await addDefaultExam(clinicSettings, token);
        const sectionData = {
          algorithm: clinicSettings.visualFieldDefaultAlgorithm,
          gridType: clinicSettings.visualFieldDefaultGridType,
          fixationPointShapeType:
            clinicSettings?.visualFieldDefaultFixationPointShapeType,
          fixationLossMethodType:
            clinicSettings?.visualFieldDefaultFixationLossMethodType ??
            FixationLossMethodOptions.EyeTracking,
          eye: null,
          distanceSphere: leftDpSphere ? leftDpSphere : "-1.0",
          distanceCylinder: leftDpCylinder ? leftDpCylinder : "-1.0",
          distanceAxis: leftDpAxis ? leftDpAxis : "-1.0",
          trialSphere: leftLtlSphere ? leftLtlSphere : "-1.0",
          trialCylinder: leftLtlCylinder ? leftLtlCylinder : "-1.0",
          trialAxis: leftLtlAxis ? leftLtlAxis : "-1.0",
          stimulusSizeType:
            clinicSettings.visualFieldDefaultStimulusSizeType ?? "III",
          order: clinicSettings.examDefaultRightEyeFirst ? 0 : 1,
        };

        const visualFieldSections = [];
        if (defaultEye === eyeLeft || defaultEye === eyeBoth) {
          sectionData.eye = eyeLeft;
          sectionData.order = clinicSettings.examDefaultRightEyeFirst ? 1 : 0;
          await addVfSection(
            token,
            newExam.id,
            sectionData,
            eyeLeft,
            visualFieldSections
          );
        }
        if (defaultEye === eyeRight || defaultEye === eyeBoth) {
          sectionData.eye = eyeRight;
          sectionData.order = clinicSettings.examDefaultRightEyeFirst ? 0 : 1;
          await addVfSection(
            token,
            newExam.id,
            sectionData,
            eyeRight,
            visualFieldSections
          );
        }
        newExam.visualFieldSections = visualFieldSections;
        setToast(() => ({
          success: true,
          message: t("exams_add_success"),
        }));
        refetchPatients();

        // add to table
        const newRows = [...rows];
        newRows.unshift(newExam);
        setRows(newRows);

        if (availableDevicesOptions?.length === 1) {
          handleLinkDeviceWithoutModal(
            newExam.id,
            availableDevicesOptions[0].id
          );
        }
      }
    } catch (e) {
      setToast(() => ({
        success: false,
        message: t("exams_add_error"),
      }));
    }
  }, [
    getAccessTokenSilently,
    clinicSettings,
    addDefaultExam,
    leftDpSphere,
    leftDpCylinder,
    leftDpAxis,
    leftLtlSphere,
    leftLtlCylinder,
    leftLtlAxis,
    eyeLeft,
    eyeBoth,
    eyeRight,
    refetchPatients,
    rows,
    setRows,
    setToast,
    availableDevicesOptions,
    addVfSection,
    handleLinkDeviceWithoutModal,
    t,
  ]);

  const rowsToRender = useMemo(() => {
    return itemsRendering(filteredRows, page, Math.max(maxNumOfRows, 4));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filteredRows, maxNumOfRows]);

  const ExamsPageIntro = useMemo(() => {
    const algDic = {
      [SCREEN_FAST]: t("word_screen_fast"),
      [FAST_THRESHOLD]: t("word_fast"),
      [STANDARD_THRESHOLD]: t("word_standard"),
      [FULL_THRESHOLD]: t("word_full"),
    };
    const gridDic = {
      [GridTypes.G10]: "10-2",
      [GridTypes.G24]: "24-2",
      [GridTypes.G24C]: "24-2C",
      [GridTypes.G30]: "30-2",
    };

    const handleDelete = async () => {
      console.log("delete button clicked");
      showLoader();
      await handleGetProgressionAnalysisMultiple();
      hideLoader();
      setDeleteModal(true);
    };

    const handleGetProgressionAnalysisMultiple = async () => {
      if (Array.isArray(selectedFull)) {
        if (selectedFull?.length >= 10) {
          console.log("Selected array is too long, stopping further calls.");
          setProgressionAnalysisFound(true);
          return; // Stop further API calls if array is too long
        }
        for (const item of selectedFull) {
          console.log(item);

          const analysisFound = await handleGetProgressionAnalysis(
            item?.patient?.id,
            item?.id
          );

          if (analysisFound) {
            console.log("Analysis found, stopping further calls.");
            break; // Stop further API calls if analysis is found
          }
        }
      }
    };

    const handleGetProgressionAnalysis = async (patientId, selectedId) => {
      const token = await getAccessTokenSilently();
      const getAnalysisStatus = await getProgressAnalysisStatus(
        token,
        clinicId,
        patientId,
        selectedId
      );

      if (Array.isArray(getAnalysisStatus?.data)) {
        if (getAnalysisStatus?.data?.length === 0) {
          console.log("No Analysis Found");
          setProgressionAnalysisFound(false);
          return false;
        } else if (getAnalysisStatus?.data?.length > 0) {
          console.log("Analysis Found");
          setProgressionAnalysisFound(true);
          return true; // Indicate that analysis was found
        }
      }

      console.log({ getAnalysisStatus });
      return false; // Default return value if no analysis found
    };

    return (
      <Grid
        item
        display={"flex"}
        alignItems={"center"}
        xs={12}
        justifyContent={"right"}
        gap={2}
        mb={{ xs: 1, sm: 0 }}
      >
        <ActionableSearchBar
          placeholder={`${t("word_search")}...`}
          onSearchChange={onFilterChange}
          onClear={clearSearchFilter}
          showDeleteButton={selected?.length > 0}
          disableDeleteButton={!shouldDelete}
          deleteButtonOnClick={handleDelete}
          showAddButton={true}
          addButtonOnClick={() => {
            clearForm();
            setAddModal(true);
          }}
          selectedRows={selected}
        />
        <Button
          sx={{
            ...ModalCustomCancel(),
            paddingY: "25px",
            width: { xs: "200px", md: "30%", lg: "25%" },
          }}
          fullWidth
          onClick={handleCreateDefaultExamClicked}
        >
          <FontAwesomeIcon icon={faPlus} />
          <Typography
            fontSize={{ xs: "13px", md: "16px" }}
            lineHeight={{ xs: "13px", md: "16px" }}
            textAlign={"center"}
            pl={0.5}
          >
            {/* Increase the font size here */}
            {`${algDic[clinicSettings?.visualFieldDefaultAlgorithm]} (${
              gridDic[clinicSettings?.visualFieldDefaultGridType]
            })`}
          </Typography>
        </Button>
      </Grid>
    );
  }, [
    FAST_THRESHOLD,
    FULL_THRESHOLD,
    SCREEN_FAST,
    clinicId,
    getAccessTokenSilently,
    selectedFull,
    STANDARD_THRESHOLD,
    clinicSettings?.visualFieldDefaultAlgorithm,
    clinicSettings?.visualFieldDefaultGridType,
    handleCreateDefaultExamClicked,
    onFilterChange,
    selected,
    shouldDelete,
    t,
  ]);

  //  calculate the number of rendered rows
  useEffect(() => {
    if (examCardRef.current) {
      const throttledFunc = throttle(() => {
        setExamCardHeight(examCardRef.current.offsetHeight);
      }, 1000);
      throttledFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examCardRef.current, examCardHeight]);
  // calculate the number of rendered rows
  useEffect(() => {
    if (height && examCardHeight) {
      // 32: padding of the row *2
      setMaxNumOfRows(
        Math.floor(
          (height - heightExceptListsContainer - examCardHeight * 2) /
            (examCardHeight + 32)
        )
      );
      const pageCount = pagesCount(filteredRows, Math.max(maxNumOfRows, 4));
      if (pageCount < page) setPage(pageCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxNumOfRows, page, examCardHeight, height]);

  const isSelected = (id) => selected.indexOf(id) !== -1;

  //Exam list element >>>>>>>>>>>>>>>
  const ListElement = () =>
    isLoading ? (
      <Loader containerHeight="30vh" />
    ) : (
      <>
        <Grid display={"flex"} mt={!rowsToRender?.length ? 3 : 0}>
          {ExamsPageIntro}
        </Grid>
        <Grid container mt={{ xs: 0, sm: 3 }}>
          <Grid item xs={12}>
            <ListHeader
              containerPaddingX={0}
              headCells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderByOptions?.[0] ?? "creationDate"}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={filteredRows.length}
              checkBoxGrid={1}
              shouldDisplayGrid="sm"
              displayFirtsBeforeCheckbox
              extraCells={extraCells}
            />
            {rowsToRender.length > 0 && (
              <Box mt={1}>
                {rowsToRender.map((row) => {
                  const isItemSelected = isSelected(row.id);
                  return (
                    <ExamCard
                      key={row.id}
                      row={row}
                      patientId={patientId}
                      isSelected={isItemSelected}
                      headCells={headCells}
                      handleCheckboxClick={handleClick}
                      handleClickToGetFullObject={handleClickToGetFullObject}
                      handleEditClick={handleEditClick}
                      handleViewReportClick={handleViewReport}
                      handleLinkDeviceClick={handleLinkDeviceClick}
                      handleUnlinkDeviceClick={handleUnlinkDeviceClick}
                      handleDuplicateClick={handleDuplicateClick}
                      associatedExamDevices={devicesWithExams}
                      linkLoading={linkLoading}
                      unlinkLoading={linkLoading}
                      ref={examCardRef}
                    />
                  );
                })}
              </Box>
            )}
          </Grid>
        </Grid>
        {rows?.length > rowsToRender?.length && (
          <PaginationWrapper
            page={page}
            count={pagesCount(filteredRows, Math.max(maxNumOfRows, 4))}
            onChange={onPaginationChange}
          />
        )}
      </>
    );

  const tabs = [
    {
      label: t("exams_table_title"),
      content: !isLoading ? ListElement() : <Loader />,
    },
    {
      label: t("progression_analysis"),
      content: !isLoading ? (
        <ProgressionAnalysisList
          patientId={patientId}
          progressAnalysisOnClick={() =>
            setShowProgressAnalysis(!progressAnalysis)
          }
          devicesWithExams={devicesWithExams}
          maxNumOfRows={maxNumOfRows}
          patientName={patientName}
        />
      ) : (
        <Loader />
      ),
    },
  ];

  // if there is no selected patient
  if (!patientId) {
    return (
      <>
        <PageIntro
          pageTitle={t("exams_title")}
          showAddButton={false}
          disableSearchButton
          showIcons={false}
        />
        <Box
          my={12}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h6" color="text.secondary">
            {t("no_patient_currently_selected")}
          </Typography>
          <Typography variant="h6" color="text.secondary" textAlign="center">
            {t("exam_display_when_selection")}
          </Typography>
        </Box>
      </>
    );
  }

  // if there are more than one selected patients
  if (isMoreThanOneSelected) {
    return (
      <>
        <PageIntro
          pageTitle={t("exams_title")}
          showAddButton={false}
          disableSearchButton
          showIcons={false}
        />
        <Box
          my={12}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h6" color="text.secondary">
            More than one patient selected.
          </Typography>
          <Typography variant="h6" color="text.secondary">
            The exams are only displayed when there is only one selected
            patient.
          </Typography>
        </Box>
      </>
    );
  }
  // patient is selected, but has no exams (the loading of exams should have finished)
  if (!isLoading && rows.length === 0 && !addModal)
    return (
      <div style={{ position: "relative" }}>
        <PageIntro
          pageTitle={`${t("exams_title")}: ${patientName}`}
          addButtonText={t("exams_add_exam")}
          addButtonOnClick={() => {
            clearForm();
            setAddModal(true);
          }}
          disableSearchButton
          showIcons={false}
        />
        <Grid container>
          <PatientInfoSection patient={patient} />
        </Grid>
        <Grid>
          <SharedTabs
            tabs={tabs}
            value={value}
            onChange={handleChangeTab}
            tabPadding={0}
          />
        </Grid>
        {value === 0 && (
          <>
            <Box
              sx={{
                position: "absolute",
                right: 260,
                top: 140,
                pointerEvents: "none",
              }}
            >
              <Box
                position={"absolute"}
                width={135}
                height={70}
                top={140}
                left={8.5}
                display={"flex"}
                alignItems={"center"}
              >
                <Typography
                  textAlign={"center"}
                  color={"#D37628"}
                  width={"100%"}
                >
                  {t("exams_modal_title")}
                </Typography>
              </Box>
              <img src={addExamArrowIcon} alt="add-exam-icon" />
            </Box>

            <Box
              my={12}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              height={"30vh"}
            >
              <Typography
                variant="h6"
                color="text.secondary"
                maxWidth={"100%"}
                mx={"auto"}
                sx={{ wordBreak: "break-all" }}
              >
                {patientName} {t("has_no_exams")}
              </Typography>
            </Box>
          </>
        )}

        {/* Add exam modal */}
        <AddExamSlider
          visible={addModal}
          onClose={handleAddClose}
          onConfirm={() => addExam()}
          examFieldType={examFieldType}
          setExamFieldType={setExamFieldType}
          examAlgorithm={examType}
          visualGrid={visualGrid}
          stimulusSizeType={stimulusSizeType}
          fixationType={fixationType}
          requiresTutorial={requiresTutorial}
          scheduledDate={scheduledDate}
          leftCheckBox={leftCheckBox}
          leftDpSphere={leftDpSphere}
          leftDpCylinder={leftDpCylinder}
          leftDpAxis={leftDpAxis}
          leftLtlSphere={leftLtlSphere}
          leftLtlCylinder={leftLtlCylinder}
          leftLtlAxis={leftLtlAxis}
          rightCheckBox={rightCheckBox}
          rightDpSphere={rightDpSphere}
          rightDpCylinder={rightDpCylinder}
          rightDpAxis={rightDpAxis}
          rightLtlSphere={rightLtlSphere}
          rightLtlCylinder={rightLtlCylinder}
          rightLtlAxis={rightLtlAxis}
          sectionOrder={sectionOrder}
          setExamAlgorithm={setExamType}
          setVisualGrid={setVisualGrid}
          setRequiresTutorial={setRequiresTutorial}
          setScheduledDate={setScheduledDate}
          setLeftCheckBox={setLeftCheckBox}
          setLeftDpSphere={setLeftDpSphere}
          setLeftDpCylinder={setLeftDpCylinder}
          setLeftDpAxis={setLeftDpAxis}
          setLeftLtlSphere={setLeftLtlSphere}
          setLeftLtlCylinder={setLeftLtlCylinder}
          setLeftLtlAxis={setLeftLtlAxis}
          setRightCheckBox={setRightCheckBox}
          setRightDpSphere={setRightDpSphere}
          setRightDpCylinder={setRightDpCylinder}
          setRightDpAxis={setRightDpAxis}
          setRightLtlSphere={setRightLtlSphere}
          setRightLtlCylinder={setRightLtlCylinder}
          setRightLtlAxis={setRightLtlAxis}
          setSectionOrder={setSectionOrder}
          setStimulusSizeType={setStimulusSizeType}
          setFixationType={setFixationType}
          devicesOptions={availableDevicesOptions}
          editExamDevice={editExamDevice}
          setEditExamDevice={setEditExamDevice}
          calibration={calibration}
          setCalibration={setCalibration}
          unlimitedTime={unlimitedTime}
          setUnlimitedTime={setUnlimitedTime}
          position={position}
          setPosition={setPosition}
          scheduledError={scheduledError}
          setScheduledError={setScheduledError}
          binocular={binocular}
          setBinocular={setBinocular}
        />
      </div>
    );

  return (
    <>
      <PageIntro
        pageTitle={`${t("exams_title")}: ${patientName}`}
        addButtonText={t("exams_add_exam")}
        addButtonOnClick={() => {
          clearForm();
          setAddModal(true);
        }}
        progressAnalysisOnClick={() =>
          setShowProgressAnalysis(!progressAnalysis)
        }
        deleteButtonOnClick={() => setDeleteModal(true)}
        onFilterChange={onFilterChange}
        clearSearchFilter={clearSearchFilter}
        selectedRows={selected}
        disableSearchButton={isLoading}
        disableDeleteButton={!shouldDelete}
        showIcons={false}
        showAddButton={false}
        showFilterInput={false}
      />

      <Grid container>
        <PatientInfoSection patient={patient} />
      </Grid>
      <Grid mt={2}>
        <SharedTabs tabs={tabs} value={value} onChange={handleChangeTab} />
      </Grid>

      <AddExamSlider
        visible={addModal}
        examType={examType}
        onClose={handleAddClose}
        onConfirm={() => addExam()}
        examFieldType={examFieldType}
        setExamFieldType={setExamFieldType}
        examAlgorithm={examType}
        visualGrid={visualGrid}
        stimulusSizeType={stimulusSizeType}
        fixationType={fixationType}
        requiresTutorial={requiresTutorial}
        scheduledDate={scheduledDate}
        leftCheckBox={leftCheckBox}
        leftDpSphere={leftDpSphere}
        leftDpCylinder={leftDpCylinder}
        leftDpAxis={leftDpAxis}
        leftLtlSphere={leftLtlSphere}
        leftLtlCylinder={leftLtlCylinder}
        leftLtlAxis={leftLtlAxis}
        rightCheckBox={rightCheckBox}
        rightDpSphere={rightDpSphere}
        rightDpCylinder={rightDpCylinder}
        rightDpAxis={rightDpAxis}
        rightLtlSphere={rightLtlSphere}
        rightLtlCylinder={rightLtlCylinder}
        rightLtlAxis={rightLtlAxis}
        sectionOrder={sectionOrder}
        setExamAlgorithm={setExamType}
        setVisualGrid={setVisualGrid}
        setRequiresTutorial={setRequiresTutorial}
        setScheduledDate={setScheduledDate}
        setLeftCheckBox={setLeftCheckBox}
        setLeftDpSphere={setLeftDpSphere}
        setLeftDpCylinder={setLeftDpCylinder}
        setLeftDpAxis={setLeftDpAxis}
        setLeftLtlSphere={setLeftLtlSphere}
        setLeftLtlCylinder={setLeftLtlCylinder}
        setLeftLtlAxis={setLeftLtlAxis}
        setRightCheckBox={setRightCheckBox}
        setRightDpSphere={setRightDpSphere}
        setRightDpCylinder={setRightDpCylinder}
        setRightDpAxis={setRightDpAxis}
        setRightLtlSphere={setRightLtlSphere}
        setRightLtlCylinder={setRightLtlCylinder}
        setRightLtlAxis={setRightLtlAxis}
        setSectionOrder={setSectionOrder}
        setStimulusSizeType={setStimulusSizeType}
        setFixationType={setFixationType}
        devicesOptions={availableDevicesOptions}
        editExamDevice={editExamDevice}
        setEditExamDevice={setEditExamDevice}
        autoPause={autoPause}
        encouragement={encouragement}
        setAutoPause={setAutoPause}
        setEncouragement={setEncouragement}
        calibration={calibration}
        setCalibration={setCalibration}
        unlimitedTime={unlimitedTime}
        setUnlimitedTime={setUnlimitedTime}
        position={position}
        setPosition={setPosition}
        scheduledError={scheduledError}
        setScheduledError={setScheduledError}
        binocular={binocular}
        setBinocular={setBinocular}
      />

      {/* Delete exam modal */}
      <DeleteExamModal
        open={deleteModal}
        onClose={handleDeleteClose}
        onConfirm={() => deleteExams()}
        onCancel={() => setDeleteModal(false)}
        progressionAnalysisFound={progressionAnalysisFound}
        progressionAnalysisFoundCount={selectedFull.length}
      />

      <ProgressAnalysis
        open={progressAnalysis}
        rows={rows}
        setOpen={setShowProgressAnalysis}
        refetchExams={refetchExams}
        clinicId={clinicId}
        selectedPatients={selectedPatients}
      />
      {/* Duplicate exam modal */}
      <DuplicateExamModal
        open={duplicateModal}
        onClose={handleDuplicateClose}
        onConfirm={duplicateExam}
        onCancel={() => setDuplicateModal(false)}
      />

      {/* Edit exam modal */}
      <EditExamSlider
        visible={editModal}
        onClose={handleEditClose}
        onConfirm={() => editExam()}
        examAlgorithm={examType}
        examFieldType={examFieldType}
        setExamFieldType={setExamFieldType}
        visualGrid={visualGrid}
        requiresTutorial={requiresTutorial}
        scheduledDate={scheduledDate}
        leftCheckBox={leftCheckBox}
        leftDpSphere={leftDpSphere}
        leftDpCylinder={leftDpCylinder}
        leftDpAxis={leftDpAxis}
        leftLtlSphere={leftLtlSphere}
        leftLtlCylinder={leftLtlCylinder}
        leftLtlAxis={leftLtlAxis}
        rightCheckBox={rightCheckBox}
        rightDpSphere={rightDpSphere}
        rightDpCylinder={rightDpCylinder}
        rightDpAxis={rightDpAxis}
        rightLtlSphere={rightLtlSphere}
        rightLtlCylinder={rightLtlCylinder}
        rightLtlAxis={rightLtlAxis}
        sectionOrder={sectionOrder}
        setExamAlgorithm={setExamType}
        setVisualGrid={setVisualGrid}
        setRequiresTutorial={setRequiresTutorial}
        setScheduledDate={setScheduledDate}
        setLeftCheckBox={setLeftCheckBox}
        setLeftDpSphere={setLeftDpSphere}
        setLeftDpCylinder={setLeftDpCylinder}
        setLeftDpAxis={setLeftDpAxis}
        setLeftLtlSphere={setLeftLtlSphere}
        setLeftLtlCylinder={setLeftLtlCylinder}
        setLeftLtlAxis={setLeftLtlAxis}
        setRightCheckBox={setRightCheckBox}
        setRightDpSphere={setRightDpSphere}
        setRightDpCylinder={setRightDpCylinder}
        setRightDpAxis={setRightDpAxis}
        setRightLtlSphere={setRightLtlSphere}
        setRightLtlCylinder={setRightLtlCylinder}
        setRightLtlAxis={setRightLtlAxis}
        setSectionOrder={setSectionOrder}
        setStimulusSizeType={setStimulusSizeType}
        setFixationType={setFixationType}
        devicesOptions={availableDevicesOptions}
        autoPause={autoPause}
        encouragement={encouragement}
        setAutoPause={setAutoPause}
        setEncouragement={setEncouragement}
        stimulusSizeType={stimulusSizeType}
        fixationType={fixationType}
        editExamDevice={editExamDevice}
        setEditExamDevice={setEditExamDevice}
        calibration={calibration}
        setCalibration={setCalibration}
        unlimitedTime={unlimitedTime}
        setUnlimitedTime={setUnlimitedTime}
        buttonDisabled={buttonDisabled}
        position={position}
        setPosition={setPosition}
        scheduledError={scheduledError}
        setScheduledError={setScheduledError}
        binocular={binocular}
        setBinocular={setBinocular}
      />

      {/* Unlink device exam modal */}
      {unlinkDeviceModal}

      {/* Link device modal */}
      <LinkDeviceModal
        open={linkDeviceModal}
        onClose={handleLinkDeviceClose}
        onConfirm={() => handleLinkDevice()}
        onCancel={() => {
          setSelectedExam("");
          setLinkDeviceModal(false);
        }}
        devicesOptions={availableDevicesOptions}
        editExamDevice={editExamDevice}
        setEditExamDevice={setEditExamDevice}
        loading={linkLoading}
      />

      {/* Unavailable device modal */}
      <UnavailableDeviceModal
        open={unavailableDeviceModal}
        onClose={handleUnavailableDeviceClose}
        onCancel={() => setUnavailableDeviceModal(false)}
      />

      <ExamTransferModal
        patientsOptions={patients}
        afterTransfer={refetchExams}
      />

      {/* Add exam success/failure toasts */}
      <SnackbarMessage
        open={Boolean(toast.message)}
        onClose={() => setToast({ success: false, message: null })}
        success={toast.success}
        autoHideDuration={8000}
        text={toast.message}
      />
    </>
  );
};

export default ExamsList;
